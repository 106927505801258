import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import DOMPurify from 'dompurify'

import Section, { TextSection } from '../components/Section'
import { queryEventTitle } from '../lib/API'
import { useData } from '../lib/hooks'
import moment from 'moment'
import Slideshow from "../components/Slideshow"



const Time = ( { start, end, repeated = false } ) => {
    let formattedStart = moment( start ).format( 'ddd HH:mm' )
    let formattedEnd = moment( end ).format( 'ddd HH:mm' )

    if( moment( start ).format( 'YYYY-MM-DD' ) === moment( end ).format( 'YYYY-MM-DD') ){
        formattedEnd = moment( end ).format( 'HH:mm' )
    }

    if( !repeated ){
        formattedStart = moment( start ).format( 'lll' )
        formattedEnd = moment( end ).format( 'lll' )
        console.log(moment( start ).format( 'YYYY-MM-DD' ),  moment( end ).format( 'YYYY-MM-DD'))
    }
    return <div>{formattedStart} - {formattedEnd}</div>
}


const Event = ( { location } ) => {
    const [ slug ] = typeof window !== 'undefined' ? window.location.href.split( '/' ).slice( -1 ) : [ '' ]
    const data =  useData( queryEventTitle,  slug )

    const localData = location.state != null ? location.state : {} 
    // console.log( data )

    
    if( ( Object.keys( localData ).length > 1 ) || data.length > 0 ){
        const { description, image, location: loc } =  localData.extra || data[0]
        const { title } = localData.extra ? localData : data[0]
        const siteTitle = "Blackheath and Charlton Baptist Church"
        const eventData = Object.keys( localData ).includes('extra') ? localData.extra : data[0]
        return (
            <div id='event-page'>
                <Helmet title={`${siteTitle} - ${title}`} />


                <section id="event-details" className="main style1 special">
                    { image && <Slideshow images={[ image ]} tint={0.25} />   } 
                    <div className="grid-wrapper">
                        <div className="col-12">
                            <header className="major">
                                <h1 style={{fontSize: '3em' }}>{title}</h1>
                            </header>
                        </div>

                        <div className="col-12">
                        <div className="grid-wrapper event-info">
                            <div className="col-6">
                                <div className='event-times'>
                                    <h3><i className='fas fa-clock' /></h3>
                                    <Time start={eventData.start} end={eventData.end} repeated={eventData.repeated} />
                                    {eventData.additional_times.map( ({ start_date, end_date }) => <Time start={start_date} end={end_date} repeated={eventData.repeated} /> )}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className='event-location'>
                                    <h3><i className='fas fa-map-marker' /></h3>
                                    {loc || "Church Building"}
                                </div>
                            </div>  
                            </div>      
                        </div>

                    
                        <div className="col-12">
                            
                            <div className='event-text'>
                                <p>{description}</p>
                            </div>
                        </div> 

                    </div>
                </section>

            {/* <section id="event-details" className="main style1 special large">
                { image && <Slideshow images={[ image ]} tint={0.4} />   } 
                <div className="grid-wrapper">
                    <div className="col-12">
                        <header className="major">
                            <h2>{title}</h2>
                        </header>
                    </div>

                   
                    <div className="col-6">
                        <div className='event-times'>
                            <h3>When:</h3>
                            {data.map( event => <Time event={event} /> )}
                        </div>
                        <div className='event-text'>
                            <p>{description}</p>
                        </div>

                    </div>
                    <div className="col-6">
                        <span className="image fit event"><img src={image || pic01} alt="" /></span>
                    </div>                

                </div>
            </section> */}
            </div>
        )
    }else{
        return <></>
    }
}

export default Event