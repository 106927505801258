import React, { useEffect, useContext, useState, useRef } from "react"
import Loader from 'react-loader-spinner'
import swal from 'sweetalert2'
import { Router } from '@reach/router'
import EventPage from '../templates/event'

import Helmet from "react-helmet"
// import FullCalendar from '@fullcalendar/react'


import Header from '../components/Header'


import NewsletterImage from '../assets/images/general/newsletter.jpg'


import { getStaticPageData, getEvents, getNewsletter, subscribeToMailchimp } from "../lib/API"
import { useLocalData, useData } from "../lib/hooks"
import BannerContext from "../lib/context"
import BannerSection from "../components/BannerSection"

import loadable from '@loadable/component'
import SmallSection, { TextSection } from "../components/Section"
import Modal from "../components/Modal"
import { changeFormValue } from "../lib/utils"



const Calendar = loadable( () => import( '../components/Calendar' ) )
const getRepeat = ( date ) =>  [ (new Date(date)).getDay() ]




const Events = () => {
    
    const [ events, setEvents ] = useState( [] )
    const [ subscribeModal , setSubscribeModal ] = useState( false )
    const [ loading, setLoading ] = useState( false )
    const [ subscribeEmail, setSubscribeEmail ] = useState( '' )
    const [ subscribeFirstName, setSubscribeFirstName ] = useState( '' )
    const [ subscribeLastName, setSubscribeLastName ] = useState( '' )


    const subscribeModalRef = useRef()

    const newsletter = useData( getNewsletter )

    const subscribe = async e => {
        e.preventDefault()
        e.stopPropagation()
        
        setLoading( true )
        const data = { email: subscribeEmail, firstName: subscribeFirstName, lastName: subscribeLastName }
        const res = await subscribeToMailchimp( data )
        setLoading( false )
        
        if( res && res.id  ) {
            if( res.status === 'pending' )
                swal.fire( 'Success', 'Verification email has been sent', 'success' ).then( () => {
                    setSubscribeEmail( '' )
                    setSubscribeFirstName( '' )
                    setSubscribeLastName( '' )
                    setSubscribeModal( false )
                } )
            else if( res.status === 'subscribed' )
                swal.fire( 'Error', 'Already subscribed', 'warning' ).then( () => {
                    setSubscribeEmail( '' )
                    setSubscribeFirstName( '' )
                    setSubscribeLastName( '' )
                    setSubscribeModal( false )
                } )
        }
        else{
            swal.fire( 'Could not subscribe', 'Something went wrong somewhere', 'error' )
        }
    }

    useEffect( () => {
        const getAllEvents = async () => {
            const res = await getEvents()
            // const eventData = res.map( event => ( {
            //     ...event,
            //     start: new Date( event.start ),
            //     end: new Date( event.end ),
            //     startTime: event.repeated && (new Date( event.start )).toLocaleTimeString('en-GB'),
            //     endTime: event.repeated && (new Date( event.end )).toLocaleTimeString('en-GB'),
            //     daysOfWeek: event.repeated && getRepeat( event.start ),
            //     allDay: event.all_day
            //   } ))
            
            const eventData = res.reduce( ( allEvents, event ) => {
                const eventObj = {
                    ...event,
                    start: new Date( event.start ),
                    end: new Date( event.end ),
                    originalStart: new Date( event.start ),
                    originalEnd: new Date( event.end ),
                    startTime: event.repeated && new Date( event.start ).toLocaleTimeString('en-GB'),
                    endTime: event.repeated && new Date( event.end ).toLocaleTimeString('en-GB'),
                    daysOfWeek: event.repeated && getRepeat( event.start ),
                    allDay: event.all_day
                }

                if( !event.additional_times ){
                    return [ ...allEvents, eventObj ]
                } else {
                    return [ 
                    ...allEvents, 
                    eventObj, 
                    ...event.additional_times.map( times => ( {
                        ...event,
                        start: new Date( times.start_date ),
                        end: new Date( times.end_date ),
                        originalStart: new Date( event.start ),
                        originalEnd: new Date( event.end ),
                        startTime: event.repeated && new Date( times.start_date ).toLocaleTimeString('en-GB'),
                        endTime: event.repeated && new Date( times.end_date ).toLocaleTimeString('en-GB'),
                        daysOfWeek: event.repeated && getRepeat( times.start_date ),
                        allDay: event.all_day
                    } ) )
                    ]
                }
            }, [] )
            setEvents( eventData )
        }

        getAllEvents()
    }, [])

    const siteTitle = "Blackheath and Charlton Baptist Church"
    
    // const headerImages = useData( getEventsHeader )
    const pageName = 'whats-on-banners'
    // const pageData = useLocalData( getStaticPageData, pageName, pageName )
    const pageData = useContext( BannerContext )[pageName]

    const { header, banners } = pageData || { header: null, banners: null }
    const headerImages = header ? header.images : []
    const headerTitle = header ? header.title : "What's On"
    // const headerIcon = <span className="icon major"><i className="fas fa-calendar-alt"></i></span>
    const headerButtons = [
        { url: "/events#one", text: "View Events" }
    ]
    
    return (
        <>
            <div id='whats-on-page'>
            
                <Helmet title={`${siteTitle} - What's On`} />
                <Header images={headerImages} headerTitle={headerTitle} tint={0.3} headerButtons={headerButtons} />

                <Calendar events={events}/>

                <TextSection
                    id='newsletter'
                    title='Newsletter'
                    style='large'
                    description='Keep up with the latest at Blackheath and Charlton Baptist Church with our weekly newsletter'
                    buttons={[
                        { external: true, url: newsletter.file, text: 'Read Newsletter', style: 'transparent-button'},
                        { text: 'Subscribe', style: 'transparent-button', onClick: () => setSubscribeModal( true ) }
                    ]}
                    background={[ NewsletterImage ]}
                    tint={0.45}
                    blur={0.4}

                />

                <Modal ref={subscribeModalRef} className="subscribe" show={subscribeModal} handleClose={() => setSubscribeModal( false )}>
                    <h2 className="major">Stay up to date with our Church Newsletter</h2>
                    <form className="login-form" method="POST" onSubmit={subscribe}>
                        <input className="form-input" type="text" name="fname" value={subscribeFirstName} onChange={e => changeFormValue( e, setSubscribeFirstName )} placeholder="First Name*" required /><br/>
                        <input className="form-input" type="text" name="lname" value={subscribeLastName} onChange={e => changeFormValue( e, setSubscribeLastName )} placeholder="Last Name*" required /><br/>
                        <input className="form-input" type="email" name="email" value={subscribeEmail} onChange={e => changeFormValue( e, setSubscribeEmail )} placeholder="Email*" required /><br/>
                        <br />
                        {!loading ? <input type="submit" value="Submit" className="button special" /> :
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={40}
                            width={50}
                        />}
                    </form>
                </Modal>
                
                
                {/* // <section id="newsletter" className="main style1 special">
                //     <header className="major">
                //         <h2>Newsletter</h2>
                //     </header>
                //     <a href={newsletter.url} ><img alt='Newsletter' className='image fit' src={newsletter.cover || NewsletterImage} /></a>
                // </section> */}


                {/* <section data-aos="fade-up" id="one" className="main style1">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <header className="major">
                                <h2>Event 1</h2>
                            </header>
                            <p>Adipiscing a commodo ante nunc accumsan et interdum mi ante adipiscing. A nunc lobortis non nisl amet vis sed volutpat aclacus nascetur ac non. Lorem curae et ante amet sapien sed tempus adipiscing id accumsan.</p>
                        </div>
                        <div className="col-6">
                            <span className="image fit"><img src={pic01} alt="" /></span>
                        </div>
                    </div>
                </section>

                <section data-aos="fade-up" id="two" className="main style2">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <span className="image fit"><img src={pic01} alt="" /></span>
                        </div>
                        <div className="col-6">
                            <header className="major">
                                <h2>Event 2</h2>
                            </header>
                            <p>Adipiscing a commodo ante nunc accumsan et interdum mi ante adipiscing. A nunc lobortis non nisl amet vis sed volutpat aclacus nascetur ac non. Lorem curae et ante amet sapien sed tempus adipiscing id accumsan.</p>
                        </div>
                        
                    </div>
                </section>

                <section data-aos="fade-up" id="three" className="main style1">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <header className="major">
                                <h2>Event 3</h2>
                            </header>
                            <p>Adipiscing a commodo ante nunc accumsan et interdum mi ante adipiscing. A nunc lobortis non nisl amet vis sed volutpat aclacus nascetur ac non. Lorem curae et ante amet sapien sed tempus adipiscing id accumsan.</p>
                        </div>
                        <div className="col-6">
                            <span className="image fit"><img src={pic01} alt="" /></span>
                        </div>
                        
                    </div>
                </section> */}

                <BannerSection banners={banners} />

            </div>
        </>
    )
}



const Page = () =>  <Router> 
                        <EventPage path="/whats-on/:id" exact />
                        <Events path="/whats-on" exact />
                    </Router>

export default Page